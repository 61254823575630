<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <div class="filter-wrap">
                    <expand-filter :formData="formData" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"
                        margin-bottom="0px">
                    </expand-filter>
                    <div class="button-line" v-has-permi="['headSetting:list']"></div>
                    <div>
                        <el-button type="primary" v-has-permi="['headSetting:save']" @click="addPerson">新建</el-button>
                    </div>
                </div>
                <table-data v-has-permi="['headSetting:list']" v-loading="loadingTable" ref="table" :config="table_config"
                    :tableData="table_data">
                    <template v-slot:operation="slotData">
                        <!-- 设置权限 v-has-permi="['operateSchoolDict:edit']" -->
                        <div class="checked-wrap">
                            <div v-has-permi="['headSetting:setting']" class="btn"
                                @click="saveReceiverDirectors(slotData.data)"
                                :class="{ 'btn-on': slotData.data.isReceiver === '1' }">
                                当前负责
                            </div>
                            <img v-if="slotData.data.isReceiver === '1'" src="@/assets/images/checked.png" alt="" />
                        </div>
                        <!-- <el-button @click="delegate(slotData.data)" class="table-success-button">当前负责</el-button> -->
                    </template>
                </table-data>
                <Pagination v-has-permi="['headSetting:list']" :total="total" :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow" @pagination="getDirectorsList"></Pagination>
                <tree-shape v-if="dialogObj.dialogVisible" :title="dialogTitle" :show="dialogObj.dialogVisible"
                    :list="selTreeOptList" :selectObjData="selectObjData" :multiple="false"
                    @getNextLevelTreeList="getNextLevelTreeList" @determine="determine" @closeDialog="closeDialog"
                    @handlerSetUser="handlerSetUser">
                </tree-shape>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    // 业务组件
    Pagination,
    // 功能组件
    Error,
    Loading,
    DialogWrapper,
    // 工具函数
    debounce
} from 'common-local'
import TableData from "./Sub/TableData";
import ExpandFilter from "./Sub/ExpandFilter";
import { mapState } from 'vuex';
import { SManagerHeadSetting } from "@/models/SManagerHeadSetting"
import { listToTree } from "@/libs/utils.js";
import { CommonModel } from '@/models/Common.js';
import TreeShape from "./TreeShape"
export default {
    name: "SManagerHeadSetting",
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        Error,
        Loading,
        TreeShape
    },
    data() {
        return {
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                deptId: '',
                name: ""
            },
            selectObjData: [],
            selTreeOptList: [],
            formData: {},
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,
            formData: {
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '姓名',
                        key: 'name'
                    },
                    {
                        type: 'cascader',
                        label: '',
                        value: '',
                        placeholder: '部门',
                        cascaderProps: {
                            label: "name",
                            multiple: false,
                            value: "id",
                            checkStrictly: true,
                            emitPath: false
                        },
                        key: 'deptId',
                        list: []
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                        auth: ["headSetting:list"]
                    },
                ],
                btnFormType: true
            },
            treeData: {
                schoolId: "",
                type: "",
                parentId: "",
                name: "",
                organType: "",
            }, // 获取树形数据
            /**
             * 树形数据开关 dialogObj
             * 树形返回数据 selTreeOptList
             * 已选择树形数据 selectObjData
             * facultyStaff 教职工
             * studentParents 学生家长
             * staffParents 教职工及学生家长
             * department 部门
             * */
            dialogObj: {
                dialogVisible: false,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "姓名",
                        prop: "name",
                        // labelWidth:"180",
                        align:'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        type: "function",
                        label: "性别",
                        prop: "sex",
                        align:'center',
                        // labelWidth:"180",
                        className: 'text-spacing-reduction',
                        callBack(row) {
                            return {
                                1: "男",
                                2: "女",
                            }[+row.sex];
                        },
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        // labelWidth:"180",
                        align:'center'
                    },
                    {
                        label: "部门",
                        prop: "deptName",
                        // labelWidth:"180",
                        align:'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "设置人",
                        prop: "createBy",
                        // labelWidth:"200",
                        align:'center'
                    },
                    {
                        type:"function",
                        label: "设置时间",
                        prop: "createTime",
                        // labelWidth:"200",
                        align:'center',
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16) : '-';
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: 'operation',
                        labelWidth: '180',
                        className: 'text-spacing-reduction'
                    }
                ],
                check: false,
                height: '',
                showIndex: true,
                isCloseRow: { key: "isReceiver", value: "1" },
            },
            options: [],
            options2: [],
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            isReceiver: 0,
        }
    },
    computed: {
        dialogTitle() {
            return `公文负责人`
        },
        ...mapState({
            schoolId: state => state.schoolId,
        }),
    },
    created() {
        this.init()
    },
    mounted() {
        this.initTableHeight()
    },
    methods: {
        init() {
            this.getDirectorsList()
            this.getOrgList()
            // this.getOrganData()
        },
        initTableHeight() {
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 145
                window.onresize = debounce(() => {
                    if (!this.$refs.table) return
                    const height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 145
                    this.table_config.height = height.toString()
                }, 200)
            })
        },
        async getTreeData() {
            const commonModel = new CommonModel();
            this.treeData.schoolId = this.schoolId;
            await commonModel.getAddressBookList(this.treeData).then((res) => {
                if (res.data.code == '200') {
                    this.selTreeOptList = res.data.data;
                    // 给树结构增加 禁用属性
                    this.selTreeOptList = this.selTreeOptList.map((item, index) => {
                        item.disabled = false
                        return item
                    })
                    // 已经存在的用户为选中状态
                    this.table_data.forEach((item, index) => {
                        this.selTreeOptList.forEach((child, childIndex) => {
                            if (item.teacherId == child.id) {
                                child.disabled = true
                            }
                        })
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        async getNextLevelTreeList(data) {
            const { id, name, onlyCheckUser} = data;
            this.treeData.parentId = id || null;
            this.treeData.name = name;
            this.treeData.onlyCheckUser = onlyCheckUser || false;
             //智能搜索到人 不能搜索到部门
             if(this.treeData.onlyCheckUser){
                this.treeData.organType = "1";
                this.treeData.type = "teacher"
                console.log(this.treeData,'---')
            }else{
                this.treeData.type = "organ,teacher";
                this.treeData.organType = "1,5";
            }
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
        },
        async addPerson() {
            this.treeData.parentId = '';
            this.treeData.type = "organ,teacher";
            this.treeData.organType = "1,5";
            await this.getTreeData();
            this.dialogObj.dialogVisible = true
            this.selectObjData = []
        },
        determine(data) {
            if(data.length == 0){
                this.$message.warning("请选择公文负责人")
                return
            }
            // 选中内容
            this.treeData.parentId = "";
            this.treeData.name = "";
            this.selectObjData = data;
            let idStr = this.selectObjData.map((i) => i.id).join(",");
            const SManagerHeadSettingJs = new SManagerHeadSetting
            SManagerHeadSettingJs.saveDirectors({ teacherId: idStr, schoolId: this.schoolId, isReceiver: this.isReceiver }).then((res) => {
                this.dialogObj.dialogVisible = false
                if (res.data.code == 200) {
                    this.$message.success('新建成功')
                    this.getDirectorsList()
                }else if (res.data.code === "160001") {
                    // 401 表示当前无账号
                    this.$confirm(
                        `目前该职工没有系统账号，请先添加后再设置为公文负责人。`,
                        "提示",
                        {
                            confirmButtonText: "去添加",
                            cancelButtonText: "取消",
                            type: "warning",
                        },
                    ).then(() => {
                        // 跳转页面
                        this.$router.push({
                            path: "/UserManagement",
                        });
                    });
                } else {
                    this.$message.error(res.data.msg)
                }

            })
        },
        // 设置是否设置为当前负责人
        handlerSetUser(data) {
            this.isReceiver = data
        },
        saveReceiverDirectors(data) {
            if (data.isReceiver === '1') {
                return
            }
            const SManagerHeadSettingJs = new SManagerHeadSetting()
            SManagerHeadSettingJs.saveReceiverDirectors({ schoolId: this.schoolId, id: data.id }).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('设置成功')
                    this.getDirectorsList()
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getOrgList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const list = res.data.data.list;
                    const list1 = list.filter((it) => it.organType == 1);
                    const list2 = list.filter((it) => it.organType != 1);
                    this.options = listToTree(list1, {
                        parentKey: "parentOrg",
                    });
                    console.log(this.options, '---')
                    this.formData.data.forEach((item) => {
                        if (item.key == "deptId") {
                            item.list = this.options;
                        }
                    });
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        // 获取负责人设置列表
        // SManagerHeadSetting.getDirectorsList
        getDirectorsList() {
            this.loadingTable = true
            this.listQuery.schoolId = this.schoolId
            const SManagerHeadSettingJs = new SManagerHeadSetting()
            console.log('this.school', this.schoolId, SManagerHeadSettingJs.getDirectorsList)
            SManagerHeadSettingJs.getDirectorsList(this.listQuery).then((res) => {
                console.log(res, '-121')
                this.loadingTable = false
                if (res.data.code == 200) {
                    console.log(res.data.data.list, 'res.data.data.list')
                    this.table_data = res.data.data.list
                    this.total = res.data.data.totalCount
                }
            })
        },
        closeDialog() {
            this.dialogObj.dialogVisible = false
            this.treeData.parentId = null
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case 'primary': //查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value
                    });
                    this.getDirectorsList()
                    break;
                case 'reset': //重置
                    this.formData.data.forEach((item) => {
                        item.value = ''
                        this.listQuery[item.key] = ''
                    });
                    this.getDirectorsList()
                    break;
                default:
                    break;
            }
        },
        changeBtnFormType() { },
        initTableHeight() {
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 145
                window.onresize = debounce(() => {
                    if (!this.$refs.table) return
                    const height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 145
                    this.table_config.height = height.toString()
                }, 200)
            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.content-wrapper{
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .expand-filter {
        padding: 0;
    }
}

.checked-wrap {
    .btn {
        display: inline-block;
        cursor: pointer;
        color: #3c7efe;
    }

    .btn:hover {
        color: #039974;
    }

    .btn-on {
        color: #039974;
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 8px;
        width: 16px;
        height: 16px;
    }
}

::v-deep .table-data .el-table .el-table__row.close-row {
    td {
        border-top: 1px dashed #039974;
        border-bottom: 1px dashed #039974;
        background-color: #e6f2ef !important;

        &:first-child {
            border-left: 1px dashed #039974;
        }

        &:last-child {
            border-right: 1px dashed #039974;
        }
    }

    .cell {
        color: #2b2c33 !important;
    }
}
</style>
